import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

const StyledBasePortableText = styled.div`
  padding: 2rem 0;
  max-width: 640px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const Small = styled.small`
  font-size: 1.2rem;
`

const Heading = props => {
  const {title, color, stripe} = props
  return (
    <>
      <StyledBasePortableText>
        <h2>お支払い済みの方へ</h2>
        <p>登録いただいたメールアドレスにレシートをお送りしております。そちらをご確認ください。</p>

        <h2>お支払い中、またはお支払いに失敗した方へ</h2>
        <p>ブラウザの戻るボタンで決済を再開できる場合がございます。</p>

        <p>
          戻るボタンで決済を再開できない場合は、再度フォームからお手付きいただくか、当学園までお問い合わせください。
        </p>

        <StyledLink to='/'>トップページへ戻る</StyledLink>

      </StyledBasePortableText>
    </>
  )
}
export default Heading
